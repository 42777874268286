import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
import { Link } from "gatsby";
import { ContactForm } from "../../components/demo-form";

// import { StaticImage } from "gatsby-plugin-image";
//import HubspotForm from "react-hubspot-form";
import {
  //mainContent,
  //introSection,
  sectionPadding,
  buttonGroup,
  flexGrid,
  orderReverse,
  column,
  sectionText,
  containerPaddingLeft,
  containerPaddingRight,
  marginTopMobile,
  //description,
  //hubSpotForm,
} from "../page.module.scss";

const LiveDemosPage = () => {
  return (
    <Layout pageTitle="Live Demos">
      <main>
        <section
          id="kontakt"
          className={`${"background-sky-blue"} ${"text-blue"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid} ${orderReverse}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1 style={{ marginBottom: 16, marginTop: 40 }}>
                Entdecken Sie unsere Live Demos
                </h1>
                <p className="text-large">Bestellen und testen Sie unsere Live Demos. Weitere Informationen zu unseren Portalen finden Sie unter den 
                <Link href="/module/#patientenportal" target="_blank" rel="noreferrer"> <span className="underline">Modulen</span></Link>.</p>

                <div className={buttonGroup}>
                  <a
                    href="https://kundenportal.tie.ch/demo/tsclient"
                    target="_blank"
                    rel="noreferrer"
                    className="button-outline"
                  >
                   health-engine
                  </a>

                  <a
                    href="https://p-p.swcode.io"
                    target="_blank"
                    rel="noreferrer"
                    className="button-outline"
                  >
                    Patientenportal
                  </a>
                  <a
                    href="https://z-p.swcode.io"
                    target="_blank"
                    rel="noreferrer"
                    className="button-outline"
                  >
                    Zuweiserportal
                  </a>
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight} ${marginTopMobile}`}
              >
                <ContactForm />
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default LiveDemosPage;
